let locationOrigin = typeof window === 'undefined' ? '' : window.location.origin

if (!locationOrigin || locationOrigin.indexOf('localhost') > -1) {
  locationOrigin = locationOrigin + '/gov'
}

const domain = locationOrigin + '/api'

const api = {
  // app
  appLoginByAccount: `${domain}/auth/login/account`,
  appIsUserLogin: `${domain}/auth/isLogin`,
  appLoginSmscode: `${domain}/auth/smscode`,
  appLoginBySms: `${domain}/auth/login/sms`,
  appLoginResetPassword: `${domain}/auth/resetpassword`,
  appGetUserInfo: `${domain}/auth/currentUserInfo`,
  appGetUserResources: `${domain}/auth/getUserResources`,
  appLogout: `${domain}/auth/logout`,
  appGetEntityByUrl: `${domain}/auth/getEntityByUrl`,
  appQueryEntityIcon: `${domain}/file/getEntityIconUrl`,
  // ***************************** common *****************************
  commonQueryOperateLog: `${domain}/log/queryList`,
  commonQueryDict: `${domain}/dict/getList`,
  // 当前用户所属区域范围
  commonQueryUserRegion: `${domain}/user/getUserRegion`,
  // 实体下所属区域范围
  commonQueryEntityRegion: `${domain}/entity/getEntityRegion`,
  commonQueryEnterpriseOpts: `${domain}/company/getCompanyByEntityId`,
  commonQueryRegions: `${domain}/adcode/queryAdCodeTree`,
  commonUploadImage: `${domain}/file/imgUpload`,
  commonUploadFile: `${domain}/file/uploadFile`,
  commonQueryRoleOpts: `${domain}/role/select/list`,
  commonQueryRoleTypeOpts: `${domain}/user/select/type/list`,
  commonQueryDecryptionResult: `${domain}/company/decry`,
  // 实体下拉框
  commonQueryUserEntities: `${domain}/entity/select/list`,
  commonQueryResourcesByEntity: `${domain}/resource/entity/tree`,
  commonQueryParkCompanyOpts: `${domain}/company/getParkCompanyInfoListByUserType`,
  // ***************************** 数据中心 *****************************
  dataCenterRegistrationAndQuota: `${domain}/dashboard/getCompanyRegistrationAndQuotaNum`,
  dataCenterViolationStatistics: `${domain}/dashboard/getViolationStatistics`,
  dataCenterViolationLineChart: `${domain}/dashboard/violation/line-chart`,
  dataCenterQueryMapInfo: `${domain}/dashboard/query/getGeoRegionInfo`,
  dataCenterRegion: `${domain}/adcode/map2D`,
  dataCenterQueryAreaRegion: `${domain}/adcode/dataCenterQueryAreaInfo`,
  dataCenterSearchBike: `${domain}/dashboard/bike/search`,
  dataCenterQueryStatisticInfo: `${domain}/dashboard/current/statistics`,
  dataCenterQueryTaskOrders: `${domain}/dashboard/taskOrder/statistics`,
  dataCenterQueryDepositionStatistics: `${domain}/dashboard/deposition/statistics`,
  dataCenterQueryCityInfo: `${domain}/dashboard/query/getBikeAggregationInfo`,
  dataCenterQueryBikeInfo: `${domain}/dashboard/query/getBikeInfo`,
  dataCenterQueryHeatMap: `${domain}/dashboard/query-heat-map`,
  dataCenterQuerySniffHeatMap: `${domain}/bluetooth-sniff/map/queryHotMap`,
  dataCenterQuerySniffSettingConfig: `${domain}/bluetooth-sniff/map/queryConf`,
  dataCenterSaveSniffSettingConfig: `${domain}/bluetooth-sniff/map/setConf`,
  dataCenterParkingAreaInfo: `${domain}/dashboard/parking/point/list`,
  dataCenterSearchParkingArea: `${domain}/dashboard/parking/point/search`,
  dataCenterPeriodOrder: `${domain}/dashboard/period/order`,
  dataCenterBikeRemoteSearch: `${domain}/dashboard/bike/search/box`,
  dataCenterPeriodActivityBike: `${domain}/dashboard/period/activity-bike`,
  dataCenterQueryPointBikeDetail: `${domain}/dashboard/parking/point/bike-detail`,
  dataCenterEditFence: `${domain}/dashboard/parking/point/update`,
  dataCenterOpratiionLineChart: `${domain}/dashboard/maintenance/line-chart`,
  dataCenterQueryOperationInfo: `${domain}/dashboard/maintenance/point/list`,
  dataCenterQuerySearchOperationInfo: `${domain}/dashboard/maintenance/point/search`,
  dataCenterQuerySearchOperationList: `${domain}/dashboard/maintenance/point/searchList`,
  dataCenterRealTimePositionQuota: `${domain}/dashboard/getRealTimeReportAndQuotaNum`,
  dataCenterQueryLayerParamConfig: `${domain}/dashboard/layerParam/config`,
  dataCenterQueryKeyAreaOverview: `${domain}/orderly/dashboard/areaDetail/overview`,
  dataCenterQueryKeyAreaLineChart: `${domain}/orderly/dashboard/areaDetail/lineChart`,
  dataCenterQueryCameraInfo: `${domain}/dashboard/queryCameraDeviceList`,
  dataCenterQueryPatrolReminder: `${domain}/orderly/dashboard/exceptionReminder`,
  dataCenterQueryOrderlyAreaRatio: `${domain}/orderly/dashboard/areaRatio`,
  dataCenterDetailsQueryList: `${domain}/orderly/dashboard/orderlyDetail/queryList`,
  dataCenterQueryOrderlyDetailList: `${domain}/dashboard/queryOrderlyDetailList`,
  dataCenterTaskOrderStatistics: `${domain}/orderly/dashboard/taskOrder/statistics`,
  dataCenterTaskOrderFinishRatioAndCostTime: `${domain}/orderly/dashboard/taskOrder/finishRatioAndCostTime`,
  dataCenterOrderPatrolDetailsStatistics: `${domain}/orderly/dashboard/orderlyDetail/lineChart`,
  dataCenterOrderBluetoothSniffStatistics: `${domain}/bluetooth-sniff/map/queryHistogram`,
  dataCenterQueryOperationTrail: `${domain}/dashboard/maintenance/trackList`,
  // ***************************** 地铁中心 *****************************
  cyclingTrajectoryDiagram: `${domain}/mock/data/cycling-trajectory-diagram`,
  diagnosticAnalysis: `${domain}/mock/data/diagnostic-analysis`,
  dataCenterQuerySubwayBikeInfo: `${domain}/mock/data/getBikeInfo`,
  // ***************************** 巡检中心 *****************************
  patrolCenterTaskOrderStatistics: `${domain}/orderly/dashboard/taskOrder/statistics`,
  patrolCenterCostTimeStatistics: `${domain}/orderly/dashboard/taskOrder/costTime/statistics`,
  patrolCenterCostTimeQueryList: `${domain}/orderly/dashboard/taskOrder/queryList`,
  patrolCenterDetailsQueryList: `${domain}/orderly/dashboard/orderlyDetail/queryList`,
  patrolCenterActualStatistics: `${domain}/orderly/dashboard/actual/statistics`,
  patrolCenterDetailsLineChart: `${domain}/orderly/dashboard/orderlyDetail/lineChart`,
  patrolCenterFenceRemoteSearch: `${domain}/orderly/dashboard/search/box`,
  patrolCenterQueryGeoRegionInfo: `${domain}/orderly/dashboard/getGeoRegionInfo`,
  patrolCenterQueryCityInfo: `${domain}/orderly/dashboard/getAggregationInfo`,
  patrolCenterQueryKeyAreaInfo: `${domain}/orderly/dashboard/areaList`,
  patrolCenterQueryKeyAreaDetail: `${domain}/orderly/dashboard/areaDetail`,
  // ***************************** 用户管理 *****************************
  userQueryList: `${domain}/user/queryList`,
  userUpdateStatus: `${domain}/user/update/status`,
  userManageRolesUnionResources: `${domain}/resource/role/tree`,
  userManageAddUser: `${domain}/user/add`,
  userManageQueryDetail: `${domain}/user/detail`,
  userManageEditUser: `${domain}/user/update`,
  userManageAuthUser: `${domain}/user/update/accredit`,
  userManageQueryAuthDetail: `${domain}/user/accredit/detail`,
  // ***************************** 角色管理 *****************************
  roleManageRoleList: `${domain}/role/list`,
  roleManageDeleteRole: `${domain}/role/delete`,
  roleManageInitialResources: `${domain}/role/initialResources`,
  roleManageRoleDetail: `${domain}/role/detail`,
  roleManageAdd: `${domain}/role/add`,
  roleManageEdit: `${domain}/role/edit`,
  // ***************************** 实体管理 *****************************
  entityManageEntityList: `${domain}/entity/list`,
  entityManageDeleteEntity: `${domain}/entity/delete`,
  entityManageEntityDetail: `${domain}/entity/detail`,
  entityManageInitialResources: `${domain}/entity/initialResources`,
  entityManageAddEntity: `${domain}/entity/create`,
  entityManageEdit: `${domain}/entity/update`,
  entityManageEditRegion: `${domain}/entity/update-region`,
  entityManageResourceTree: `${domain}/entity/getEntityResourceTree`,
  entityManageBusinessStrategyOptions: `${domain}/regulation-support/business-strategy/options`,
  entityManageEditResourceName: `${domain}/resource/entity/update`,
  entityManageUploadCustomAdcodeFileCode: `${domain}/customAdcode/upload`,
  entityManageCustomAdcodeFileCodeTemplate: `${domain}/customAdcode/downloadTemplate`,
  entityManageAdCodeListAndCustomAreaTree: `${domain}/entity/region`,
  // ***************************** 企业管理 *****************************
  // 列表查询
  companyQueryList: `${domain}/company/page`,
  // 添加车企
  companyCreate: `${domain}/company/create`,
  // 查看详情
  companyQueryDetail: `${domain}/company/detail`,
  // 品牌名称下拉框
  companyBrandNameList: `${domain}/company/getBrandNameList`,
  // 修改车企信息
  companyUpdate: `${domain}/company/update`,
  // 删除车企
  companyDelete: `${domain}/company/delete`,
  // 修改详情
  companyUpdateDetail: `${domain}/company/updateDetail`,
  // 新增修改配额
  companySaveOrUpdateQuota: `${domain}/quotaRegion/saveOrUpdateQuota`,
  // 配额回显   /quotaRegion
  companyGetQuotaDetail: `${domain}/quotaRegion/getCompanyQuota`,
  // 品牌名称
  companyBrandList: `${domain}/company/getBrandList`,
  companyBrandListByUserType: `${domain}/company/getBrandListByUserType`,
  companyGetQuotaTab: `${domain}/quotaRegion/getQuotaTab`,
  // ***************************** 管理员管理 *****************************
  // 列表查询
  administratorManageQueryList: `${domain}/user/admin/queryList`,
  // 管理员信息修改
  administratorUpdate: `${domain}/user/update`,
  // 管理员修改详情
  administratorUpdateDetail: `${domain}/user/update/detail`,
  // 管理员信息查询
  administratorDetail: `${domain}/user/detail`,
  // 管理员启用状态修改
  administratorUpdateStatus: `${domain}/user/update/status`,
  // 添加管理员
  administratorAdminAdd: `${domain}/user/admin/add`,
  // ***************************** 车辆管理 *****************************
  // 列表
  bikeManageGetQueryList: `${domain}/registrationVehicle/getPageList`,
  // 车辆品牌列表及车企代码
  bikeManageGetAllCompanyListByEntityId: `${domain}/company/getAllCompanyListByEntityId`,
  // 模版下载
  bikeManageGetFileTemplate: `${domain}/registrationVehicle/getFileTemplate`,
  // 备案车上传文件
  bikeManageUploadFile: `${domain}/registrationVehicle/uploadFile`,
  // 备案车文件提交
  bikeManageCommitFile: `${domain}/registrationVehicle/commitFile`,
  //导出结果
  bikeManageExportQueryResult: `${domain}/registrationVehicle/exportQueryResult`,
  bikeManageRegistrationRuleSave: `${domain}/registration-validate-rule/save`,
  bikeManageQueryRegistrationRule: `${domain}/registration-validate-rule/query`,
  // 实时定位上报车辆管理-查询
  bikeManageGetRealTimePositionList: `${domain}/vehicleReport/getPageList`,
  // 实时定位上报车辆管理-导出
  bikeManageExportRealTimePositionResult: `${domain}/vehicleReport/exportQueryResult`,
  // 实时定位上报车辆管理-配置
  bikeManageUpdateRealTimePosition: `${domain}/entity/addEffectHoursConfig`,
  // 实时定位上报车辆管理-查询配置
  bikeManageQueryRealTimePosition: `${domain}/entity/getEffectHoursConfig`,
  bikeRegistereVerifyAddress: `${domain}/verification/queryAddress`,
  // ***************************** 备案核验信息 *****************************
  bikeRegistereRecordsList: `${domain}/verification/records`,
  bikeRegistereExportQueryResult: `${domain}/verification/exportQueryResult`,
  bikeRegistereCancel: `${domain}/verification/cancel`,
  // ***************************** 违规告警 *****************************
  violationAlarmQueryList: `${domain}/violation/queryList`,
  violationAlarmExportQueryResult: `${domain}/violation/exportQueryResult`,
  violationAlarmViolationCreateOrder: `${domain}/violation/create-order`,
  violationAlarmConfig: `${domain}/violation/config`,
  violationAlarmGetConfig: `${domain}/violation/getConfig`,
  violationAlarmGetTypeConfig: `${domain}/violation/getTypeConfig`,
  violationQueryTypeAndSourceOpts: `${domain}/violation/getTypeAndSource`,
  // ***************************** 任务工单 *****************************
  taskCenterQueryResult: `${domain}/taskOrder/queryList`,
  taskCenterResponsibleConfig: `${domain}/taskOrder/useLumpSum`,
  // taskCenterQuerySaveTypeConfig: `${domain}/taskOrder/saveTypeConfig`,
  taskCenterQuerySaveTypeConfig: `${domain}/taskOrder/save-type-config`,
  // taskCenterQueryGenerateConfig: `${domain}/taskOrder/getGenerateConfig`,
  taskCenterQueryStrategyConfigList: `${domain}/taskOrder/strategy-config-list`,
  taskCenterQueryTypeConfig: `${domain}/taskOrder/getTypeConfig`,
  // taskCenterQueryByLonLat: `${domain}/adcode/queryGovAdCodeInfoByLonLat`,
  taskCenterQueryLocationByLngLat: `${domain}/adcode/queryLocationByLonLat`,
  taskCenterQuerydispatch: `${domain}/taskOrder/dispatch`,
  taskCenterGetStatusList: `${domain}/taskOrder/getStatusList`,
  taskCenterRxportQueryResult: `${domain}/taskOrder/exportQueryResult`,
  taskCenterGetFlowRecord: `${domain}/taskOrder/getFlowRecord`,
  taskCenteReview: `${domain}/taskOrder/review`,
  taskCenteInvalid: `${domain}/taskOrder/invalid`,
  taskCenteQueryAllTypeOpts: `${domain}/taskOrder/getAllTypeList`,
  taskCenteGetUserRegionDetailInfo: `${domain}/user/getUserRegionDetailInfo`,
  taskCenteGetAllEffectiveCompanyListByEntityId: `${domain}/company/getAllEffectiveCompanyListByEntityId`,
  taskCenterQueryOrderSourceConfig: `${domain}/taskOrder/orderSourceConfig`,
  taskCenterQueryStatusTrendData: `${domain}/taskOrder/orderStatusDistribution`,
  taskCenterQueryOrderCountTrend: `${domain}/taskOrder/orderCountTrend`,
  // ***************************** 围栏管理 *****************************
  fenceManageDepositionConfig: `${domain}/fencing/depositionConfig`,
  fenceManageDelete: `${domain}/fencing/delete`,
  fenceManageQueryList: `${domain}/fencing/queryList`,
  fenceManageExportQueryResult: `${domain}/fencing/exportQueryResult`,
  fenceManageGetFileTemplate: `${domain}/fencing/getFileTemplate`,
  fenceManageUploadFile: `${domain}/fencing/uploadFile`,
  fenceManageCommitFile: `${domain}/fencing/commitFile`,
  fenceManageUpdate: `${domain}/fencing/update`,
  fenceManageSave: `${domain}/fencing/save`,
  fenceManageDetail: `${domain}/fencing/detail`,
  fenceManageChangeStatus: `${domain}/fencing/changeStatus`,
  fenceManageQueryBikeDetail: `${domain}/fencing/queryBikeDetail`,
  fenceManageCenterPoint: `${domain}/fencing/calc/centerPoint`,
  fenceManageQueryMapFences: `${domain}/fencing/point/list`,
  fenceManageQueryDepositionConfig: `${domain}/fencing/getDepositionConfig`,
  fenceManageCalculateCapacity: `${domain}/fencing/capacity/calculate`,
  fenceManageBatchChangeStatus: `${domain}/fencing/batchChangeStatus`,
  fenceManageBatchDelete: `${domain}/fencing/batchDelete`,
  fenceManageGetStatistics: `${domain}/violation-alarm/deposition-snapshot/getStatistics`,
  fenceManageDepositStatisticQueryPage: `${domain}/violation-alarm/deposition-history/query-page`,
  fenceManageExportExcel: `${domain}/violation-alarm/deposition-history/export-excel`,
  fenceManageDepositionTypeEnum: `${domain}/violation-alarm/deposition-history/query-deposition-type-enum`,
  fenceManageCreateOrde: `${domain}/violation-alarm/deposition-history/create-order`,
  fenceManageQueryBikeDetailById: `${domain}/violation-alarm/deposition-history/query-bike-details-by-id`,
  fenceManageSaveOrUpdatePoint: `${domain}/area-management/orderly/check/batchSaveOrUpdatePoint`,
  fenceManageQueryPointList: `${domain}/area-management/orderly/check/getPointList`,
  // ***************************** 围栏管理-秩序重点区域 *****************************
  orderKeyRegionList: `${domain}/area-management/orderly/page`,
  orderKeyRegionDelete: `${domain}/area-management/orderly/delete`,
  orderKeyRegionDetail: `${domain}/area-management/orderly/detail`,
  orderKeyRegionSave: `${domain}/area-management/orderly/save-or-update`,
  orderKeyRegionQueryMapFences: `${domain}/area-management/orderly/scope-all-list`,
  orderKeyRegionFenceRemoteSearch: `${domain}/area-management/orderly/search/suggest`,
  orderKeyRegionUploadFile: `${domain}/area-management/orderly/upload`,
  orderKeyRegionFileTemplate: `${domain}/area-management/orderly/file-template`,
  orderKeyRegionCommitFile: `${domain}/area-management/orderly/commit-file`,
  orderKeyRegionExportResult: `${domain}/area-management/orderly/export`,
  // ***************************** 秩序管理-重点区域表现 *****************************
  keyRegionPerformOrderlyResultOpts: `${domain}/orderly/overview/getConfig`,
  keyRegionPerformList: `${domain}/orderly/overview/getPageOverview`,
  keyRegionPerformDetail: `${domain}/orderly/overview/queryDetailWithStatistic`,
  // ***************************** 秩序管理-智能巡检明细 *****************************
  intelligentInspectionEntityConfigDisplayColumn: `${domain}/orderly/overview/getEntityConfigDisplayColumn`, // 查询实体配置的所有模型展示字段
  intelligentInspectionPageDetailList: `${domain}/orderly/overview/getPageDetailList`, // 巡视明细列表查询(分页)
  intelligentInspectionQueryDetailListToExcel: `${domain}/orderly/overview/queryDetailListToExcel`, // 巡视明细查询结果导出
  intelligentInspectionQueryUserNameList: `${domain}/user/queryUserNameList`, // 巡视人列表
  intelligentInspectionAuditConfig: `${domain}/orderly/overview/getAuditConfig`, // 人工审核开关查询接口
  intelligentInspectionHandleAudit: `${domain}/orderly/overview/detail/audit`, // 人工审核
  intelligentInspectionGetDetailInfo: `${domain}/orderly/overview/detail/getDetailInfo`, // 秩序巡检详情
  // ***************************** 考核配置 *****************************
  examineConfigQueryPage: `${domain}/evaluationCycle/queryPage`,
  examineConfigDelete: `${domain}/evaluationCycle/delete`,
  examineConfigQueryById: `${domain}/evaluationCycle/queryById`,
  examineConfigSaveOrUpdate: `${domain}/evaluationCycle/saveOrUpdate`,
  examineConfigItemQueryList: `${domain}/evaluationItem/queryList`,
  examineConfigItemQueryPage: `${domain}/evaluationItem/queryPage`,
  examineConfigItemAdd: `${domain}/evaluationItem/add`,
  examineConfigItemEdit: `${domain}/evaluationItem/edit`,
  examineConfigItemGetCalculateEnum: `${domain}/evaluationItem/getCalculateEnum`,
  examineConfigGetEvaluationCycleDurationEnumList: `${domain}/evaluationCycle/getEvaluationCycleDurationEnumList`,
  examineConfigCheckExistsResult: `${domain}/evaluationCycle/checkExistsResult`,
  examineConfigStatusChange: `${domain}/evaluationCycle/release`,
  examineConfigPublish: `${domain}/evaluationCycle/publish`,
  examineConfigPublishCheck: `${domain}/evaluationCycle/publishCheck`,
  examineConfigItemChangeStatus: `${domain}/evaluationItem/changeStatus`,
  // ***************************** 考核结果 *****************************
  evaluationResult: `${domain}/evaluationResult`,
  evaluationResultCorrection: `${domain}/evaluationResult/correction`,
  evaluationCycleQueryList: `${domain}/evaluationCycle/queryList`,
  // ***************************** 订单管理 *****************************
  orderDetailsList: `${domain}/orderDetails/queryPage`,
  // ***************************** 拍照识别溯源 *****************************
  photographQuerySelectList: `${domain}/orderly-regulation/bike-return-record/query-return-status-list`,
  photographQueryPage: `${domain}/orderly-regulation/bike-return-record/query-page`,
  // ***************************** 蓝牙嗅探 *****************************
  bluetoothSniffList: `${domain}/bikere-registration/bluetooth-sniff/getPageList`,
  bluetoothSniffGetDetail: `${domain}/bikere-registration/bluetooth-sniff/getDetail`,
  bluetoothSniffSettings: `${domain}/bikere-registration/bluetooth-sniff/settings`,
  bluetoothSniffSettingsUpdate: `${domain}/bikere-registration/bluetooth-sniff/settings-update`,
  bluetoothSniffExport: `${domain}/bikere-registration/bluetooth-sniff/export`,
  // ***************************** 运维信息 *****************************
  operateVehicleList: `${domain}/data-center/maintenance/vehicle/list`,
  operateVehicleExportExcel: `${domain}/data-center/maintenance/vehicle/export-excel`,
  operateVehiclePersonnelList: `${domain}/data-center/maintenance/personnel/list`,
  operateVehiclePersonnelExportExcel: `${domain}/data-center/maintenance/personnel/export-excel`,
  operateVehicleGetMaintenanceBikeTypeEnumMap: `${domain}/data-center/maintenance/getMaintenanceBikeTypeEnumMap`,
  operateVehicleStatisticsToday: `${domain}/dashboard/maintenance/today/statistics`,
  operateVehicleTrackList: `${domain}/data-center/maintenance/detail/track/list`,
  operateVehicleQueryPage: `${domain}/data-center/maintenance/detail/query-page`,
  operateOrderWorkQueryPage: `${domain}/maintenance/taskOrder/queryPage`,
  operateOrderWorkStatis: `${domain}/maintenance/taskOrder/statis`,
  // 摄像头管理
  nationalStandardVideoOnlineVideo: `${domain}/device/management/onlineVideo`, // 视频预览
  nationalStandardVideoQueryPageList: `${domain}/device/management/queryChannelPageList`, // 通道管理分页查询
  nationalStandardVideoQueryDelete: `${domain}/device/management/delete`, // 设备删除
  nationalStandardVideoQueryUpdate: `${domain}/device/management/updateChannel`, // 通道信息编辑
  nationalStandardVideoSaveCameraConf: `${domain}/device/saveAutoInspectionConf`, // 摄像头设置
  nationalStandardVideoQueryCameraConf: `${domain}/device/queryAutoInspectionConf`, // 摄像头设置查询接口
  nationalStandardServiceQueryInfo: `${domain}/device/service/queryBaseConfig`, // 当前平台信息查询
  nationalStandardServiceUpdateInfo: `${domain}/device/service/updateBaseConfig`, // 当前平台信息更新
  nationalStandardServiceFetchInfo: `${domain}/device/service/fetchInfo`, // 下级平台信息主动更新
  // API管理
  apiDiyList: `${domain}/apidiy/list`, // 映射列表查询
  apiDiyExport: `${domain}/apidiy/export`, // 映射列表导出
  apiDiyDetail: `${domain}/apidiy/detail`, // 数据详情查询
  apiDiyUpsert: `${domain}/apidiy/upsert`, // 数据更新
  apiDiyGetOriginInterfaceList: `${domain}/apidiy/getOriginInterfaceList`, // 原始接口信息获取
  apiDiyOnline: `${domain}/apidiy/online`, // 接口上线
  apiDiyOffline: `${domain}/apidiy/offline`, // 接口上线
}

module.exports = api
