import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// ========================= CityInfo =========================
export type CityInfo = {
  adCode: string
  bikeCount: number
  lat: number
  lng: number
  name: string
}
// ========================= BikeInfo =========================
export type MapBikeInfo = {
  batteryVoltage: number
  bikeId: string
  bikeReportLngLat: LngLat
  lat: number
  lng: number
  useIot: boolean
  bikeStatus: BikeStatus
  bikeType: BusinessType
  cardStatus: any
  frameId: any
  licenseCode: any
}[]

export type MapSpockInfo = MapBikeInfo

export type AllMapBikeInfo = { bikeLocations: MapBikeInfo; spockLocations: MapSpockInfo }
// ========================= AreaInfo =========================
export type AreaInfo = {
  center: LngLat
  region: LngLat[][][]
}

// ========================= ParkingArea =========================
export type ParkingArea = {
  id: number
  parkCompanyId: number
  isSearched: boolean
  rawRegion: { type: string; coordinates: number[][][] }
  centerPoint: LngLat
  region: LngLat[][][]
  fencingBizId: string
  fencingId: string
  fencingName: string
  areaType: ParkingAreaType
  depositionCount: string
  fencingCapacity: string
  depositionName: string
  fencingCapacitySource: 1 | 2 // 围栏容量设置 2系统计算 1自行维护
}

// ========================= OperationInfo =========================
export type OperationInfo = {
  entityId: Entity['entityId']
  operationType: OperateStatus
  brandCode: string
  companyId: string
  companyName: string
  location: LngLat
  businessId: string
  maintainedBikeType: BusinessType
  latestReportTime: string
  name: string
  phoneNumber: string
  encryptName: string
  encryptPhoneNumber: string
  vehicleType: string
  vehicleLicense: string
}[]

// ========================= KeyArea =========================
export type KeyArea = {
  rawRegion: { type: string; coordinates: number[][][] }
  centerPoint: LngLat
  region: LngLat[][][]
  fencingId: string
  fencingName: string
  orderlyResult: OrderlyType
  orderlyResultName?: string
}

// ========================= Camera =========================
export type Camera = {
  id: number
  channelId: string
  deviceId: number
  channelName: string
  longtitude: number
  latitude: number
  deviceType: number
  fencingId: string
  fencingName: string
  address: string
  orderlyResult: OrderlyType
  orderlyResultName: string
}

// ========================= SniffInfo =========================
export type SniffInfo = {
  sniffTime: string
  longitude: number
  latitude: number
  bikeStatistic?: {
    totalCount: number
    noRegistrationCount: number
  }
  spockStatistic?: {
    totalCount: number
    noRegistrationCount: number
  }
}[]
export interface SniffConfig {
  timeConf: number
  hotConf: Record<'green' | 'yellow' | 'red', [number, number]>
}

export interface DataCenterState {
  cityInfo: CityInfo[]
  areaInfo: AreaInfo
  editedFence: ParkingArea
  bikeInfo: AllMapBikeInfo
  heatMapInfo: AllMapBikeInfo
  searchBikeInfo: AllMapBikeInfo
  parkingAreaInfo: ParkingArea[]
  operationInfo: OperationInfo
  searchOperationInfo: OperationInfo
  selTopDepositFence: ParkingArea
  bufferParkingAreaInfo: ParkingArea[]
  searchParkingAreaInfo: ParkingArea[]
  searchBufferParkingAreaInfo: ParkingArea[]
  keyAreaInfo: KeyArea[]
  searchKeyAreaInfo: KeyArea[]
  cameraInfo: Camera[]
  searchCameraInfo: Camera[]
  sniffInfo: SniffInfo
  sniffConfig: SniffConfig
}

export const initCityInfo = []
export const initBikeInfo = { bikeLocations: [], spockLocations: [] } as AllMapBikeInfo
export const initAreaInfo = {} as AreaInfo
export const initParkingAreaInfo = [] as ParkingArea[]
export const initOperationInfo = [] as OperationInfo
export const initKeyAreaInfo = [] as KeyArea[]

const initialState: DataCenterState = {
  cityInfo: initCityInfo,
  bikeInfo: initBikeInfo,
  heatMapInfo: initBikeInfo,
  areaInfo: initAreaInfo,
  searchBikeInfo: initBikeInfo,
  selTopDepositFence: initParkingAreaInfo[0],
  editedFence: initParkingAreaInfo[0],
  parkingAreaInfo: initParkingAreaInfo,
  operationInfo: initOperationInfo,
  searchOperationInfo: initOperationInfo,
  bufferParkingAreaInfo: initParkingAreaInfo,
  searchParkingAreaInfo: initParkingAreaInfo,
  searchBufferParkingAreaInfo: initParkingAreaInfo,
  keyAreaInfo: initKeyAreaInfo,
  searchKeyAreaInfo: initKeyAreaInfo,
  cameraInfo: [],
  searchCameraInfo: [],
  sniffInfo: [],
  sniffConfig: {} as SniffConfig,
}

export const appSlice = createSlice({
  name: 'dataCenter',
  initialState,
  reducers: {
    updateSelTopSiltedFence: (state, action: PayloadAction<ParkingArea>) => {
      state.selTopDepositFence = action.payload
    },
    updateEditedFence: (state, action: PayloadAction<ParkingArea>) => {
      state.editedFence = action.payload
    },
    updateCityInfo: (state, action: PayloadAction<CityInfo[]>) => {
      state.cityInfo = action.payload
    },
    updateBikeInfo: (state, action: PayloadAction<AllMapBikeInfo>) => {
      state.bikeInfo = action.payload
    },
    updateSearchBikeInfo: (state, action: PayloadAction<AllMapBikeInfo>) => {
      state.searchBikeInfo = action.payload
    },
    updateAreaInfo: (state, action: PayloadAction<AreaInfo>) => {
      state.areaInfo = action.payload
    },
    updateParkingAreaInfo: (state, action: PayloadAction<ParkingArea[]>) => {
      state.parkingAreaInfo = action.payload
    },
    updateBufferParkingAreaInfo: (state, action: PayloadAction<ParkingArea[]>) => {
      state.bufferParkingAreaInfo = action.payload
    },
    updateSearchParkingAreaInfo: (state, action: PayloadAction<ParkingArea[]>) => {
      state.searchParkingAreaInfo = action.payload
    },
    updateSearchBufferParkingAreaInfo: (state, action: PayloadAction<ParkingArea[]>) => {
      state.searchBufferParkingAreaInfo = action.payload
    },
    updateHeatMapInfo: (state, action: PayloadAction<AllMapBikeInfo>) => {
      state.heatMapInfo = action.payload
    },
    updateOperationInfo: (state, action: PayloadAction<OperationInfo>) => {
      state.operationInfo = action.payload
    },
    updateSearchOperationInfo: (state, action: PayloadAction<OperationInfo>) => {
      state.searchOperationInfo = action.payload
    },
    updateKeyAreaInfo: (state, action: PayloadAction<KeyArea[]>) => {
      state.keyAreaInfo = action.payload
    },
    updateSearchKeyAreaInfo: (state, action: PayloadAction<KeyArea[]>) => {
      state.searchKeyAreaInfo = action.payload
    },
    updateCameraInfo: (state, action: PayloadAction<Camera[]>) => {
      state.cameraInfo = action.payload
    },
    updateSearchCameraInfo: (state, action: PayloadAction<Camera[]>) => {
      state.searchCameraInfo = action.payload
    },
    updateSniffHeatMapInfo: (state, action: PayloadAction<SniffInfo>) => {
      state.sniffInfo = action.payload
    },
    updateSniffConfig: (state, action: PayloadAction<SniffConfig>) => {
      state.sniffConfig = action.payload
    },
  },
})

export const {
  updateSearchBikeInfo,
  updateCityInfo,
  updateAreaInfo,
  updateBikeInfo,
  updateEditedFence,
  updateHeatMapInfo,
  updateOperationInfo,
  updateParkingAreaInfo,
  updateSelTopSiltedFence,
  updateSearchOperationInfo,
  updateBufferParkingAreaInfo,
  updateSearchParkingAreaInfo,
  updateSearchBufferParkingAreaInfo,
  updateKeyAreaInfo,
  updateSearchKeyAreaInfo,
  updateCameraInfo,
  updateSearchCameraInfo,
  updateSniffHeatMapInfo,
  updateSniffConfig,
} = appSlice.actions

export default appSlice.reducer
