// 角色类型
export const roleTypeMap: Record<'managerRole' | 'platformRole' | 'enterpriseRole', RoleType> = {
  managerRole: 0, // 管理员角色
  platformRole: 1, // 平台用户角色
  enterpriseRole: 2, // 企业用户角色
}

export const roleTypeTextMap: Record<RoleType, string> = {
  0: '管理员角色',
  1: '平台用户角色',
  2: '企业用户角色',
}

export const businessLineTextMap: Record<BusinessType, string> = {
  1: '单车',
  2: '电单车',
  3: '双业务',
}

export const businessLineMap: Record<'bike' | 'spock' | 'double', BusinessType> = {
  bike: 1,
  spock: 2,
  double: 3,
}

export const bikeStatusMap: Record<'all' | 'riding' | 'parking', BikeStatus> = {
  all: 3,
  riding: 1,
  parking: 2,
}

export const bikeStatusTextMap: Record<BikeStatus, string> = {
  3: '全部',
  1: '骑行中',
  2: '停放中',
}

export const parkingAreaMap: Record<'parking' | 'noParking' | 'operateArea', BikeStatus> = {
  parking: 1,
  operateArea: 2,
  noParking: 3,
}

export const parkingAreaTextMap: Record<ParkingAreaType, string> = {
  1: 'P点',
  2: '运营区',
  3: '禁停区',
}

export const operateStatusMap: Record<'operator' | 'operationCar', OperateStatus> = {
  operator: 1,
  operationCar: 2,
}

export const operateStatusTextMap: Record<OperateStatus, string> = {
  1: '运维人员',
  2: '运维车辆',
}

export const heatStatusMap: Record<'vehicle' | 'sniffing', HeatStatus> = {
  // start: 1,
  vehicle: 2,
  sniffing: 3,
}

export const heatStatusTextMap: Record<HeatStatus, string> = {
  // 1: '出发地热力图',
  2: '车辆热力图',
  3: '嗅探热力图',
}

export const timePeriodMap = {
  today: 0,
  seven: 7,
  thirity: 30,
}

export const timePeriodTextMap = {
  0: '今天',
  7: '近7天',
  30: '近30天',
}

export const brandCodeTextMap = {
  MT: '美团',
  HB: '哈啰',
  QJ: '青桔',
  DH: '大哈',
  QF: '骑福',
  CY: '车云',
  QTCX: '蜻蜓',
  QD: '骑电',
  LSM: '锂市民',
  XL: '小遛',
  BD: '北斗禹州',
  YZX: '禹州行',
  YJN: '禹见你',
  YAX: '永安行',
  ML: '毛驴',
  DD: '叮咚',
  LX: '乐行',
  LD: '乐迪',
  CL: '畅驴',
}

export const brandCodeMap = {
  mt: 'MT',
  hb: 'HB',
  qj: 'QJ',
  dh: 'DH',
  qf: 'QF',
  cy: 'CY',
  qtcx: 'QTCX',
  qd: 'QD',
  lsm: 'LSM',
  xl: 'XL',
  bd: 'BD',
  yzx: 'YZX',
  yjn: 'YJN',
  yax: 'YAX',
  ml: 'ML',
  dd: 'DD',
  lx: 'LX',
  ld: 'LD',
  cl: 'CL',
}

export const bikeCardStatusTextMap = {
  1: '牌照完整',
  2: '牌照丢失',
  3: '牌照损坏',
}

export const districtLevelMap = {
  country: 2,
  province: 4,
  city: 5,
  area: 6,
  street: 7,
  school: 8,
}

export const orderlyTypeMap: Record<'orderly' | 'unorderly' | 'offline', OrderlyType> = {
  orderly: 1,
  unorderly: 2,
  offline: 3,
}

export const orderlyTypeTextMap: Record<OrderlyType, string> = {
  1: '秩序整齐',
  2: '秩序散乱',
  3: '不在线',
}

export const bucketTypeMap = {
  public: 1, // 公有桶
  private: 2, // 私有桶
}

// 围栏容量来源
export const fenceCapacitySourceMap = {
  system: 2, // 系统
  manual: 1, // 自行维护
}

export const depositionDegreeMap = {
  normal: 1,
  serious: 2,
  medium: 3,
  mild: 4,
}

export const depositionDegreeTextMap = {
  1: '正常',
  2: '严重',
  3: '中度',
  4: '轻度',
}

/**
 * 定义行政区类型的枚举值
 * 1: 表示标准行政区
 * 2: 表示自定义行政区
 */
export const regionTypeMap = {
  standard: 1,
  custom: 2,
}

export const regExpMap = {
  // 整数
  int: /^-?[1-9]\d*$/,
  // 正整数
  posInt: /^[1-9]\d*$/,
  posIntAndZero: /^([1-9]\d*|0)$/,
  // 座机
  phone: /^0\d{2,3}-?\d{7,8}$/,
  // 手机号
  mobile: /^((\+?86)|(\(\+86\)))?1\d{10}$/,
  // 脱敏手机号
  secureMobile: /^((\+?86)|(\(\+86\)))?(1\d{2}(\*|\d){4}\d{4}$)$/,
  // 身份证
  idCard: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
  // 邮箱
  email: /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/,
  // 中文姓名
  name: /[\u4E00-\u9FA5\uF900-\uFA2D]/,
  // url
  url: /^(https?|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/,
  // 用户密码
  userPassword:
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[~!@#$%^&*()_.])[\da-zA-Z~!@#$%^&*()_.]{8,16}$/,
  // 添加管理员密码
  amodalPassword: /(?!^(\d+|[a-zA-Z]+|[~!@#$%^&*()_.]+)$)^[\w~!@#$%^&*()_.]{8,16}$/,
  // ip 地址
  ip: /((2((5[0-5])|([0-4]\d)))|([0-1]?\d{1,2}))(\.((2((5[0-5])|([0-4]\d)))|([0-1]?\d{1,2}))){3}/,
}

export const resCodeMap = {
  success: 0,
  unauthorized: 401,
  forbidden: 403,
  notFound: 404,
}

export type ResourceMapKeys = keyof typeof resourceMap

export type ResourceMap = {
  [K in ResourceMapKeys]: Record<keyof (typeof resourceMap)[K], UserResource | null>
}

export const defaultSubjectColor = 'black'
export const defaultMapBackgroundColor = 'black'
export const chartAnimationDuration = 2000

export const themeList = [
  {
    label: '黑色',
    value: 'black',
  },
  {
    label: '蓝色',
    value: 'blue',
  },
  {
    label: '绿色',
    value: 'green',
  },
  {
    label: '科技蓝',
    value: 'technologyBlue',
  },
]

export const mapThemeConfigList = [
  {
    label: '黑色',
    value: 'black',
  },
  {
    label: '灰色',
    value: 'grey',
  },
  {
    label: '白色',
    value: 'white',
  },
]

export const metricLayoutList = [
  {
    label: '左',
    value: 'left',
  },
  {
    label: '右',
    value: 'right',
  },
]

export const defaultCompanyOpts = [{ label: '全部', value: -999 }]

export const periodOpts = [
  { label: '今日', value: 1 },
  { label: '近7日', value: 2 },
  { label: '近30日', value: 3 },
]

export const oneDayStamp = 24 * 60 * 60 * 1000
export const oneHourStamp = 60 * 60 * 1000

export const resourceMap = {
  // 数据中心
  dataCenter: {
    index: '1',
    registration: 'datacenter:registration:menu', // 车辆备案情况
    registrationQuotaNum: 'datacenter:registration:quotaNum', // 车辆备案情况-配额总量
    registrationRegisNum: 'datacenter:registration:registrationNum', // 车辆备案情况-备案车总量
    registrationQuotaRatio: 'datacenter:registration:quotaRatio', // 车辆备案情况-配额使用率
    violationStatistics: 'datacenter:violation:menu', // 车辆违规情况
    todayStatistic: 'datacenter:statistics:menu', // 实时统计指标
    statisticOperatingBike: 'datacenter:statistics:operatingBike', // 实时统计指标-运营车指标
    operationStatistics: 'maintenance:statistics', // 运维力量统计
    operationOperatorInfo: 'maintenance:person', // 大屏运维人员信息
    operationOperateCarInfo: 'maintenance:vehicle', // 大屏运维车辆信息
    taskorderMenu: 'datacenter:taskorder:menu', // 任务工单
    bikeOperation: 'datacenter:operation', // 运营车辆分布
    districtShowBikeCount: 'show:district:street:bike:number', // 区县、街道显示车辆数量
    bikeOperationShowInfo: 'datacenter:operation:bike', // 运营车辆分布，显示车辆信息弹窗
    showBikeLicenseCode: 'datacenter:operation:license', // 显示车牌号
    showBikeFrameId: 'datacenter:operation:vin', // 显示车架号
    parkingSiltedMenu: 'parking:deposition:statistics', // 停车点淤积情况
    parkingAreaShow: 'parking:point:distribute', // 停车点分布
    heatMapLayerShow: 'datacenter:heat:map', // 车辆热力图
    heatMapSniffLayerShow: 'datacenter:heat:map:sniff', // 嗅探热力图
    roadNetworkDistribute: 'road:network:distribute', // 路网分布图
    periodStatistic: 'datacenter:cycleStatistics:menu', // 周期统计数据
    orderStatistics: 'datacenter:order:statistics', //订单统计
    activeBikeStatistics: 'datacenter:activeBike:statistics', //活跃车统计
    todayCarbon: 'datacenter:statistics:todayCarbon', //今日碳减排
    todayActiveBike: 'datacenter:statistics:todayActiveBike', // 今日活跃车
    todayOrderCount: 'datacenter:statistics:todayOrderCount', //今日订单量
    todayCyclingMileage: 'datacenter:statistics:todayCyclingMileage', //今日骑行里程
    todayRealtimeVehicle: 'datacenter:statistics:realtimeBike', //实时运营车辆数
    bikeRealTimePosition: 'datacenter:bikeReport:menu', // 实时上报车辆情况
    bikeRealTimePositionQuotaNum: 'datacenter:bikeReport:quotaNum', // 实时上报车辆情况 - 配额总量
    bikeRealTimePositionQuotaRatio: 'datacenter:bikeReport:quotaRatio', // 实时上报车辆情况 - 配额使用率
    bikeRealTimePositionReportNum: 'datacenter:bikeReport:reportNum', // 实时上报车辆情况 - 实时上报车辆总量
    keyAreaDistribute: 'datacenter:orderly:areaDistribution', // 重点区域分布
    cameraDistribute: 'datacenter:camera:distribution', // 摄像头分布
    exceptionReminder: 'datacenter:exception:reminder', // 异常提醒
    orderlyAreaRatio: 'datacenter:statistics:orderlyFencingRatio', // 有序区域实时占比
    roadSurfaceOrderLiveStatus: 'datacenter:statistics:realTimeDetail', // 路面秩序实况
    orderIssueProcessStatistic: 'datacenter:order:statusDistribution', // 秩序问题处理实况
    orderPatrolDetailStatistics: 'datacenter:orderly:detailDistribution', // 秩序巡检详情统计
    sniffStatistics: 'datacenter:registration:sniffStatistic', // 嗅探统计
    operationWorkOrderStatistics: 'maintenance:task:statistics', // 运维工单统计- 数据大屏
  },
  // 地铁中心
  subwayCenter: {
    index: 'subway:center',
  },
  // 巡检中心
  patrolCenter: {
    index: 'overview:data:center',
  },
  // 管理中心
  manageCenter: {
    index: 'manage:center',
    authManage: 'manage:permission', // 权限管理
    userManage: 'manage:user', // 用户管理
    roleManage: 'manage:role', // 角色管理
    enterpriseManage: 'manage:enterprise:menu', // 车企管理
    enterpriseManageList: 'manage:enterprise:list', // 车企管理-列表
    enterpriseManageAdd: 'manage:enterprise:add', // 车企管理-新增
    enterpriseManageDelete: 'manage:enterprise:delete', // 车企管理-删除
    enterpriseManageUpdate: 'manage:enterprise:update', // 车企管理-修改
    enterpriseManageQuota: 'manage:enterprise:quota', // 车企管理-配额
    administratorManage: 'manage:admin', // 管理员管理
    entityManage: 'manage:entity', // 实体管理
    bikeManage: 'management:vehicle:parent:menu', // 车辆管理
    bikeRecordManage: 'management:vehicle:menu', // 车辆备案管理
    bikeRecordShowLastRecordTime: 'management:vehicle:time', // 车辆备案管理-显示最后备案时间
    verificationMenu: 'management:verification:menu', // 车辆核验信息
    verificationList: 'management:verification:list', // 车辆核验信息-列表
    verificationCancel: 'management:verification:cancel', // 车辆核验信息-撤回核验记录
    verificationShowQrCode: 'management:verification:qrcode', // 车辆核验信息-显示核验二维码
    violationAlarm: 'violation:center', // 违规告警
    violationAlarmConfig: 'violation:center:config', // 违规告警-设置
    violationAlarmList: 'violation:center:list', // 违规告警列表
    violationAlarmDispatch: 'violation:center:dispatch', // 违规告警新增派单
    bikeManageVehicleVin: 'management:vehicle:vin', // 查看车架号
    bikeManageVehicleLicense: 'management:vehicle:license', // 查看车辆牌照
    bikeManageVehicleImport: 'management:vehicle:import', // 更新备案车
    bikeManageVehicleExport: 'management:vehicle:export', // 车辆管理导出
    bikeManageVehicleSetting: 'management:vehicle:setting', // 车辆管理备案车设置
    taskCenter: 'task:center', // 任务中心
    taskCenterConfig: 'task:type:config', // 任务中心-设置
    taskCenterDispatch: 'task:order:dispatch', // 任务中心-新增派单
    taskCenterList: 'task:order:list', // 任务中心-列表
    taskCenterManualOrderSpecial: 'order:dispatch:special', // 任务中心-手动工单-专项
    taskCenterManualOrderNormal: 'order:dispatch:normal', // 任务中心-手动工单-日常
    fenceManageList: 'fencing:center:menu', // 围栏管理
    fenceManageAllFence: 'fencing:center:all', // 围栏管理-全部围栏
    fenceManageDeposition: 'fencing:deposition:statis', // 围栏管理-围栏淤积统计
    fenceManageDepositionStatisList: 'fencing:deposition:statis:list', // 围栏管理-围栏淤积统计列表
    fenceManageDepositionStatisTrend: 'fencing:deposition:statis:trend', // 围栏管理-停车点趋势图
    fenceManageOrderKeyRegion: 'orderly:area', // 围栏管理-秩序重点区域
    fenceManageDraw: 'fencing:center:add', // 围栏管理-绘制
    fenceManageDelete: 'fencing:center:delete', // 围栏管理-删除
    fenceManageUpdate: 'fencing:center:update', // 围栏管理-编辑
    fenceManageConfig: 'fencing:deposition:config', // 围栏管理-设置
    fenceManageExcelExport: 'fencing:excel:export', // 围栏管理-导出
    fenceManageExcelImport: 'fencing:excel:import', // 围栏管理-导入
    examineManage: 'fencing:excel:import', //考核管理
    examineConfig: 'evaluation:settings', //考核配置
    examineConfigCycleCreate: 'evaluation:cycle:create', //考核配置-新增考核期
    examineConfigItemCreate: 'evaluation:item:create', //考核配置-新增考核条目
    examineConfigCycleList: 'evaluation:list', //考核配置-考核期列表页
    examineOverview: 'evaluation:overview', //'考核管理-考核总览'
    examineOverviewCorrection: 'overview:correction', // 考核总览-校正
    examineOverviewOperationLog: 'overview:operation:log', //考核总览-操作日志
    examineOverviewlLst: 'overview:list', //考核总览-列表页
    orderManage: 'management:order:menu', // 订单管理
    orderList: 'management:order:list', //订单信息列表页
    photographMangeMenu: 'photo:recognition:menu', // 拍照还车
    photographMangeList: 'photo:recognition:list', //拍照还车列表
    bluetoothSniff: 'bluetooth:sniff', //蓝牙嗅探
    bluetoothSniffList: 'bluetooth:sniff:list', //蓝牙嗅探列表页
    bluetoothSniffConfig: 'bluetooth:sniff:config', //蓝牙嗅探设置
    operationManage: 'maintenance:info', // 运维信息管理
    operationOperatorManage: 'maintenance:info:person', // 运维信息管理-运维人员
    operationCarManage: 'maintenance:info:vehicle', // 运维信息管理-运维车辆
    operationWorkOrder: 'maintenance:task:info', // 运维信息管理-运维工单
    inquiryEvidence: 'manage:survey:menu', // 管理中心-调查取证
    orderlyManage: 'orderly:manage', //管理中心-秩序管理
    keyRegionPerform: 'orderly:key:area:manifestation', // //管理中心-秩序管理-重点区域表现
    keyOrderlyDetail: 'orderly:key:area:orderlyDetail', //管理中心-秩序管理-智能巡检明细
    cameraManage: 'camera:manage:menu', // 管理中心-摄像头管理
    cameraChannelManage: 'camera:manage:channel:menu', // 管理中心-摄像头管理-通道管理
    cameraConfigManage: 'camera:manage:congfig:menu', // 管理中心-摄像头管理-服务配置
    bikeManageReportPositionMenu: 'management:report:menu', // 车辆定位上报管理
    bikeManageReportPositionConfig: 'management:report:setTime', // 车辆定位上报管理 - 车辆定位时效配置
    bikeManageReportPositionTime: 'management:report:time', // 车辆定位上报管理 - 定位最新上报时间
    bikeManageReportPositionExport: 'management:report:export', // 车辆定位上报管理 - 导出
    apiManagement: 'management:api', // 管理中心-API管理
  },
}
