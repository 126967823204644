const themeMap = {
  css: {
    '--theme-header-bg': '#121A29', // 顶部栏背景色
    '--theme-body-bg': '#10131a', // 页面背景色
    '--theme-header-img': '', // 顶部栏背景图
    '--theme-header-title-text-shadow': 'none', // 顶部栏title text shadow
    '--theme-header-title-font-size': '24px', // 顶部栏title 字体大小
    '--theme-header-title-font-weight': '600', // 顶部栏title 字体权重
    '--theme-ant-select-selector-border-color': 'rgba(255, 255, 255, 0.12)', // 顶部栏选择框边框颜色
    '--theme-header-downoutlined-color': 'rgba(255, 255, 255, 0.28)', // 顶部栏下拉菜单箭头颜色
    '--theme-content-box-left-icon-url': '', // content box 顶部左边图标
    '--theme-content-box-top-bg': 'none', // content box 顶部渐变色
    // 通用
    '--theme-legend-mask-bg': '#424b5c', // tree/select/input组件下拉框背景色、图层、图例等mask背景色
    '--theme-legend-bg': 'rgba(27, 35, 51, 0.88)', // 图层、图例等背景色
    '--theme-tooltip-indicator-color': '#748399', // 图层、图例等文字
    '--theme-tooltip-indicator-sub-color': '#748399', // 统计模块subtext等文字
    '--theme-tooltip-indicator-label-color': '#bccbe0', // 图层、图例等文字
    '--theme-tooltip-divider-bg': 'rgba(116, 131, 153, 0.2)', // 图层、图例等分割线
    '--theme-indicator-num-bg': '#1F344D', // 统计指标-数字框背景色
    '--theme-indicator-num-color': '#00E0E0', // 统计指标-数字颜色
    '--theme-indicator-num-box-shadow':
      '0px 2px 6px 0px rgba(3, 13, 26, 0.06), inset 0px 0px 2px 0px rgba(255, 255, 255, 0.2)', // 统计指标-数字框box shadow
    '--theme-indicator-num-border-bg': 'none', // 统计指标-数字框border
    '--theme-content-box-sel-biz-color': '#4bd2de', // 统计指标-业务线选中颜色
    '--theme-content-box-biz-color': 'rgba(255, 255, 255, 0.44)', // 统计指标-业务线默认颜色
    '--theme-header-title-color': '#166ff7', // 单个数据中心高亮色
    '-theme-legend-select-item-default-color': '#424b5c', // 图层选择项默认颜色
    '-theme-legend-select-item-selected-color': 'rgba(41, 101, 255, 0.2)', // 图层选择项选中颜色
    '--theme-legend-select-item-selected-border-color': '#2965ff', // 图层选择项选中边框颜色
    '--theme-map-dialog-bg': '#424b5c', // 地图 - 弹窗背景色
    '--theme-common-dialog-bg': '#424b5c', // 弹窗背景色
    '--theme-common-dialog-module-bg': '#313d4b', // 弹窗-模块背景色
    '--theme-common-dialog-table-th-bg': '#505a66', // 弹窗-表格表头背景色
    '--theme-common-dialog-table-td-bg': '#3d4856', // 弹窗-表格单元格背景色
    '--theme-collapse-btn-color': '#fff', // 折叠按钮color
    '--theme-orderly-alarm-bg-color': '#eb5038', // 异常提醒背景色

    // 统计模块
    '--theme-content-box-bg': '#1b2333', // 背景色
    '--theme-content-box-border-bg': 'linear-gradient(0deg, #1b2333 0%, #1b2333 100%) border-box', // 边框色
    '--theme-content-box-title-bg': '#00e0e0', // 标题竖线颜色
    '--theme-content-box-bg-url': `url(${require('_root/assets/img/content_box_bg.png')})`, // 标题竖线背景图

    // 地图
    '--theme-map-search-input-group-bg': 'rgba(27, 35, 51)', // 地图-输入框背景色
    '--theme-map-search-select-bg': '#313847', // 地图-下拉选择背景色
    '--theme-map-modal-confrim-btn-bg': '#4bd2de', // 地图-弹窗确认按钮背景色
    '--theme-map-table-th-bg': '#545c6b', // 地图-table 表头按钮背景色
    '--theme-map-heat-slider-bg': '#1b2332', // 地图-热力图slider 背景色
    '--theme-map-heat-slider-border-color': '#1b2332', // 地图-热力图slider border color
    '--theme-map-heat-slider-default-line-color': 'rgba(255, 255, 255, 0.24)', // 地图-热力图slider 线条默认颜色
    '--theme-map-heat-slider-highlight-line-color': '#0d4eb0', // 地图-热力图slider 线条高亮颜色
    '--theme-map-heat-slider-hover-line-color': '#0d4eb0', // 地图-热力图slider 线条hover颜色
    '--theme-map-heat-slider-active-dot-color': '#8bb7fb', // 地图-热力图slider 选中点颜色
    '--theme-map-heat-slider-mark-color': 'rgba(255, 255, 255, 0.5)', // 地图-热力图slider 字体颜色
    '--theme-map-city-info-border-color': '#3feefd', // 地图-城市信息气泡border颜色
    '--theme-map-city-info-bg': 'rgba(63, 238, 253, 0.8)', // 地图-城市信息气泡颜色
    '--theme-map-city-info-color': '#151e2d', // 地图-城市信息气泡主color
    '--theme-map-city-operation-info-color': 'rgba(0, 0, 0, 0.35)', // 地图-城市信息气泡 操作人员等信息color
    '--theme-layer-control-item-outer-bg': 'transparent', // 边框色
    '--theme-map-city-info-outer-box-bg-img': '', // area_donut_outer
    '--theme-map-city-info-outer-box-bg': 'transparent', // 地图-城市信息气泡背景
    '--theme-map-city-info-outer-box-shadow': 'transparent', // 地图-城市信息气泡背景阴影-默认色
    '--theme-map-city-info-name-font-weight': '500',
    '--theme-map-city-info-name-font-size': '14px',
    '--theme-map-city-info-name-font-family': 'PingFangSC-Medium',
    '--theme-map-city-info-operation-font-weight': '400',
    '--theme-map-city-info-operation-font-size': '12px',
    '--theme-map-city-info-operation-font-family': 'AvenirBold',
    '--theme-map-city-info-count-font-weight': '900',
    '--theme-map-city-info-count-font-size': '24px',
    '--theme-map-city-info-count-font-family': 'Avenir',
  },
  '--theme-china-map-area-color-primary-0': '#3865D0', // 中国地图-有实体区域背景色 0%
  '--theme-china-map-area-color-primary-100': '#3865D0', // 中国地图-有实体区域背景色 100%
  '--theme-china-map-area-color-ghost-0': '#465A7D', // 中国地图-无实体区域背景色 0%
  '--theme-china-map-area-color-ghost-100': '#465A7D', // 中国地图-无实体区域背景色 100%

  '--theme-china-map-area-color-primary-hover-0': '#2965FF', // 中国地图-有实体区域背景色 hover 0%
  '--theme-china-map-area-color-primary-hover-100': '#2965FF', // 中国地图-有实体区域背景色 hover 100%
  '--theme-china-map-area-color-ghost-hover-0': '#596B8A', // 中国地图-无实体区域背景色 hover 0%
  '--theme-china-map-area-color-ghost-hover-100': '#596B8A', // 中国地图-无实体区域背景色 hover 100%

  '--theme-china-map-area-border-color-primary': '#268BFF', // 中国地图-有实体区域边框色
  '--theme-china-map-area-border-color-ghost': '#7190C3', // 中国地图-无实体区域边框色

  '--theme-echarts-subtext-color': '#748399', // echart subTitle color
  '--theme-echarts-y-axis-split-line-color': '#748399', // echart折线图 y轴分割线color
  '--theme-echarts-x-axis-split-line-color': 'rgba(116, 131, 153, 0.4)', // echart折线图 x轴分割线color
  '--theme-echarts-y-axis-axis-line-color': '#748399', // echart折线图 y轴轴线color
  '--theme-echarts-x-axis-axis-line-color': '#748399', // echart折线图 x轴轴线color
  '--theme-echarts-x-axis-axis-label-color': '#748399', // echart折线图 x轴label color
  '--theme-parking-normal-color': '#49CAD6', // 正常停车点统计指标color
  '--theme-content-box-pie-border-color': '#1b2333', // echart饼图 border color
  '--theme-echarts-bar-color-1': '#EB5038', // 运维力量统计 柱状图柱子颜色
  '--theme-echarts-bar-color-2': '#4BD2DE', // 车辆备案情况、实时上报车辆情况 柱状图柱子颜色
  '--theme-echarts-bar-color-3': 'rgba(25, 35, 55, 1)', // 车辆备案情况、实时上报车辆情况 柱状图柱子颜色
  '--theme-echarts-bar-color-4': 'rgba(48, 66, 92, 1)', // 车辆备案情况、实时上报车辆情况 柱状图柱子颜色
  '--theme-tooltip-hover-color': 'rgba(66, 75, 92, 0.2)', // echart图 toolTip hover颜色
  '--theme-tooltip-hover-color-1': 'rgba(66, 75, 92, 0.2)', // 地图-热力图slider 线条hover颜色 - 0%
  '--theme-tooltip-hover-color-2': 'rgba(66, 75, 92, 0.2)', // 地图-热力图slider 线条hover颜色 - 100%

  '--theme-map-area-polygon-bg': 'rgba(22,112,247,0.06)', // 地图区域-polygon背景色
  '--theme-map-area-polygon-border-color': 'rgba(22,112,247,1)', // 地图区域-polygon border color
  '--theme-map-cluster-bubble-close': '#aaa', // 地图info close color
  '--theme-map-area-donut-operator': `${require('_root/assets/img/areaDonutIcon/donut_operator.png')}`, // 地图气泡操作人icon
  '--theme-map-area-donut-car': `${require('_root/assets/img/areaDonutIcon/donut_operation_car.png')}`, // 地图气泡操作人icon

  '--theme-trend-down-img': `${require('_root/assets/img/trend_down_black.png')}`, // 工单实况下降箭头

  '--theme-order-patrol-detail-statistics-effective-count': '#AEF2F2', // 秩序巡视详情统计 有效巡视次数颜色
  '--theme-order-patrol-detail-statistics-nest-ratio': '#0095B2', // 秩序巡视详情统计 秩序整齐率颜色

  '--theme-operation-work-order-statistics-dispatch': '#45E5BD', // 运维工单统计 调度颜色
  '--theme-operation-work-order-statistics-charging': '#00B2FF', // 运维工单统计 换电颜色
  '--theme-operation-work-order-statistics-maintain': '#0088FF', // 运维工单统计 维修颜色

}

export default themeMap
